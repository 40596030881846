import { ArrowRight } from "@mui/icons-material";
import { Link } from "@mui/material";
import { JobCard } from "../components/JobCard";
import { JobsResponse } from "../hooks/useJobs";

export const Opportunities = ({
  handleOpenModal,
  jobs,
  showTitle = true,
}: {
  handleOpenModal: (id: number) => void;
  jobs: JobsResponse["data"];
  showTitle?: boolean;
}) => (
  <div className="bg-gray-50 py-2 w-full">
    <div className="mx-auto px-4">
      {showTitle && (
        <h2 className="text-3xl font-bold text-center text-gray-900 mb-12">
          Oportunidades te esperando
        </h2>
      )}
      <div className="flex flex-col gap-6">
        {jobs.map((job) => (
          <JobCard
            key={job.id}
            handleOpenModal={handleOpenModal}
            jobData={job}
          />
        ))}
      </div>
      <div className="text-center mt-10">
        <Link href={`/vagas`}>
          <button className="px-8 py-3 bg-green-500 text-white rounded-lg hover:bg-green-600 inline-flex items-center space-x-2">
            <span>Ver todas as vagas</span>
            <ArrowRight className="w-4 h-4" />
          </button>
        </Link>
      </div>
    </div>
  </div>
);

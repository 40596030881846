import { useQuery } from "@tanstack/react-query";

interface Benefit {
  benefit: {
    id: number;
    title: string;
  };
  value: number;
}

interface Activity {
  position_type: {
    id: number;
    title: string;
  };
}

interface Company {
  city: string;
  state: string;
}

interface TypeWorking {
  title: string;
}

interface TypeHiring {
  title: string;
}

interface Level {
  title: string;
}

export interface FilterParams {
  id?: number;
  position?: {
    title: string;
  };
  salary_min?: number;
  salary_max?: number;
  types_working?: {
    title: string;
  };
  types_hiring?: {
    title: string;
  };
  state?: string;
  city?: string;
  zone_id?: number;
}

export interface Job {
  id: number;
  title: string;
  company: Company;
  salary_defined: boolean;
  salary_min: number;
  salary_max: number;
  type_working: TypeWorking;
  type_hiring: TypeHiring;
  created_at: string;
  city: string;
  state: string;
  position_amount: number;
  activities: Activity[];
  requirements: Activity[];
  level: Level;
  zone: { title: string };
}

interface System {
  system: {
    id: number;
    title: string;
  };
}

export interface JobDetail extends Job {
  observations: string;
  date_start: string | null;
  working_day: string;
  others_activities: string;
  requirements: Activity[];
  others_requirements: string;
  systems: System[];
  benefits: Benefit[];
  others_benefits: string;
}

export interface JobsResponse {
  data: Job[];
  pagination: {
    total: number;
    pages: number;
    current_page: number;
    per_page: number;
  };
}

export interface JobDetailResponse {
  data: JobDetail;
}

const API_URL = "https://m9s31qy2f7.execute-api.us-east-1.amazonaws.com"; //"http://localhost:8787";
const API_KEY =
  "d0caa3e319a9c65774572f5446ff5c510918341c7783eb782dd76beb263cae9c";

export function useJobs(
  page: number = 1,
  perPage: number = 10,
  filters?: FilterParams
) {
  return useQuery<JobsResponse>({
    queryKey: ["jobs", page, perPage, filters],
    queryFn: async () => {
      const filterParam = filters ? `&filters=${JSON.stringify(filters)}` : "";
      const response = await fetch(
        `${API_URL}/jobs?page=${page}&per_page=${perPage}${filterParam}`,
        {
          headers: {
            "X-Api-Key": API_KEY,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch jobs");
      }

      return response.json();
    },
  });
}

export function useJobDetail(id: number) {
  return useQuery<JobDetailResponse>({
    queryKey: ["job", id],
    queryFn: async () => {
      const response = await fetch(`${API_URL}/jobs/${id}`, {
        headers: {
          "X-Api-Key": API_KEY,
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch job details");
      }

      return response.json();
    },
    enabled: !!id,
  });
}

export const formatCurrency = (
  cents: number,
  prefix: boolean = true
): string => {
  const value = cents / 100;

  if (value >= 1000) {
    const thousands = value / 1000;
    const formatted = Number.isInteger(thousands)
      ? thousands.toString()
      : thousands.toFixed(1).replace(".0", "");
    return prefix ? `R$ ${formatted}K` : `${formatted}K`;
  }

  return prefix ? `R$ ${value}` : value.toString();
};

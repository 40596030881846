export function getTimeElapsed(date: string | Date | undefined): string {
  if (!date) return "";

  const start = new Date(date);
  const now = new Date();

  const diffInHours = Math.floor(
    (now.getTime() - start.getTime()) / (1000 * 60 * 60)
  );

  if (diffInHours < 24) {
    if (diffInHours === 0) {
      return "menos de 1 hora";
    }
    return `${diffInHours} ${diffInHours === 1 ? "hora" : "horas"}`;
  }

  const diffInDays = Math.floor(diffInHours / 24);
  return `${diffInDays} ${diffInDays === 1 ? "dia" : "dias"}`;
}

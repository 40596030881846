export const formatDate = (
  date: string | Date | undefined | null,
  options?: {
    withTime?: boolean;
    withWeekday?: boolean;
  }
): string => {
  if (!date) return "-";

  const dateObj = typeof date === "string" ? new Date(date) : date;

  if (isNaN(dateObj.getTime())) return "-";

  const weekdays = [
    "Domingo",
    "Segunda",
    "Terça",
    "Quarta",
    "Quinta",
    "Sexta",
    "Sábado",
  ];

  const day = dateObj.getDate().toString().padStart(2, "0");
  const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
  const year = dateObj.getFullYear();
  let formatted = `${day}/${month}/${year}`;

  if (options?.withTime) {
    const hours = dateObj.getHours().toString().padStart(2, "0");
    const minutes = dateObj.getMinutes().toString().padStart(2, "0");
    formatted += ` ${hours}:${minutes}`;
  }

  if (options?.withWeekday) {
    const weekday = weekdays[dateObj.getDay()];
    formatted = `${weekday}, ${formatted}`;
  }

  return formatted;
};

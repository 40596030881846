import { Building2, ChevronRight, Clock, MapPin } from "lucide-react";
import { JobsResponse } from "../hooks/useJobs";
import { getTimeElapsed } from "../utils/dateUtils";
import { formatCurrency } from "../utils/formatCurrency";

const JobCard = ({
  handleOpenModal,
  jobData,
}: {
  handleOpenModal: (id: number) => void;
  jobData: JobsResponse["data"][number];
}) => {
  return (
    <div className="bg-white rounded-xl p-6 shadow-sm hover:shadow-md transition-shadow border border-gray-100">
      {/* Header com logo e informações principais */}
      <div className="flex justify-between items-start mb-4">
        <div className="flex gap-4 items-center">
          <div className="w-12 h-12 bg-gray-100 rounded-lg flex items-center justify-center">
            <Building2 className="w-6 h-6 text-gray-400" />
          </div>
          <div>
            <h3 className="font-semibold text-xl text-gray-900 mb-1">
              {jobData?.title}
            </h3>
            <div className="flex items-center text-gray-500 text-sm">
              <MapPin className="w-4 h-4 mr-1" />
              {jobData?.zone?.title
                ? `${jobData?.city} - ${jobData?.zone?.title} - ${jobData?.state}`
                : `${jobData?.city} - ${jobData?.state}`}
            </div>
          </div>
        </div>
        <span className="flex items-center text-sm text-gray-500">
          <Clock className="w-4 h-4 mr-1" />
          Publicada há {getTimeElapsed(jobData?.created_at)}
        </span>
      </div>

      {/* Descrição */}
      {/* <p className="text-gray-600 mb-4 line-clamp-2">
        Empresa líder no setor contábil busca profissional experiente para
        liderar equipe e processos contábeis...
      </p> */}

      {/* Tags e Informações */}
      <div className="space-y-3 mb-4">
        <div className="flex flex-wrap gap-2">
          {jobData?.type_hiring?.title && (
            <span
              className={`px-3 py-1 bg-purple-50 text-purple-600 rounded-full text-sm font-medium`}
            >
              {jobData?.type_hiring?.title}
            </span>
          )}
          {jobData?.type_working?.title && (
            <span
              className={`px-3 py-1 bg-green-50 text-green-600 rounded-full text-sm font-medium`}
            >
              {jobData?.type_working?.title}
            </span>
          )}
          {jobData?.salary_min && jobData?.salary_max && (
            <span
              className={`px-3 py-1 bg-blue-50 text-blue-600 rounded-full text-sm font-medium`}
            >
              {formatCurrency(jobData?.salary_min)} -{" "}
              {formatCurrency(jobData?.salary_max)}
            </span>
          )}

          {!jobData?.salary_min && !jobData?.salary_max && (
            <span className="px-3 py-1 bg-gray-50 text-gray-600 rounded-full text-sm font-medium">
              Salário a combinar
            </span>
          )}
        </div>

        {/* Requisitos principais */}
        <div className="grid grid-cols-2 gap-2 text-sm text-gray-600">
          {jobData?.requirements?.map((requirement) => (
            <div className="flex items-center">
              <span className="w-1.5 h-1.5 bg-gray-400 rounded-full mr-2"></span>
              {requirement.position_type?.title}
            </div>
          ))}
        </div>
      </div>

      <div className="flex items-center justify-end pt-4 border-t border-gray-100">
        <button
          onClick={() => handleOpenModal(jobData?.id)}
          className="flex items-center text-blue-600 hover:text-blue-700 font-medium"
        >
          Ver detalhes
          <ChevronRight className="w-4 h-4 ml-1" />
        </button>
      </div>
    </div>
  );
};

export { JobCard };

import { Link } from "@mui/material";
import {
  AlertCircle,
  Award,
  Building2,
  Calendar,
  CheckCircle2,
  Clock,
  MapPin,
  Users,
  X,
} from "lucide-react";
import { useJobDetail } from "../hooks/useJobs";
import { formatCurrency } from "../utils/formatCurrency";
import { formatDate } from "../utils/formatters";
import { Loading } from "./Loading";

const JobDetailsModal = ({
  id,
  onClose,
}: {
  id: number;
  onClose: () => void;
}) => {
  const { data, isLoading } = useJobDetail(id);

  if (isLoading)
    return (
      <div className="flex justify-center items-center p-8">
        <Loading />
      </div>
    );

  return (
    <div className="bg-white rounded-xl max-w-4xl w-full mx-auto relative">
      <div
        className="absolute top-2 right-2 cursor-pointer p-4"
        onClick={onClose}
      >
        <X className="w-5 h-5 text-gray-400" />
      </div>
      <div className="p-8 border-b border-gray-100">
        <div className="flex items-center gap-6 mb-6">
          <div className="w-16 h-16 bg-purple-100 rounded-xl flex items-center justify-center flex-shrink-0">
            <Building2 className="w-8 h-8 text-purple-600" />
          </div>
          <div className="flex-1">
            <div className="flex items-start justify-between gap-4">
              <div>
                <h2 className="text-2xl font-semibold text-gray-900 mb-2">
                  {data?.data?.title}
                </h2>
                <p className="text-gray-600">Connectabil</p>
              </div>
              {/* <span className="px-3 py-1 bg-purple-100 text-purple-600 rounded-lg text-sm font-medium">
                TESTE
              </span> */}
            </div>
            <div className="flex flex-wrap gap-4 mt-4">
              <span className="inline-flex items-center text-gray-600">
                <MapPin className="w-4 h-4 mr-1" />
                {data?.data?.city}, {data?.data?.state}
              </span>
              <span className="inline-flex items-center text-gray-600">
                <Users className="w-4 h-4 mr-1" />
                {data?.data?.position_amount} vagas
              </span>
              <span className="inline-flex items-center text-gray-600">
                <Clock className="w-4 h-4 mr-1" />
                {data?.data?.type_working?.title}
              </span>
              <span className="inline-flex items-center text-gray-600">
                <Calendar className="w-4 h-4 mr-1" />
                Início: {formatDate(data?.data?.date_start)}
              </span>
            </div>
          </div>
        </div>

        <div className="flex flex-wrap gap-2">
          <span className="px-3 py-1 bg-blue-50 text-blue-600 rounded-full text-sm">
            {data?.data?.type_working?.title}
          </span>
          <span className="px-3 py-1 bg-green-50 text-green-600 rounded-full text-sm">
            {data?.data?.type_hiring?.title}
          </span>
          {!data?.data?.salary_min && !data?.data?.salary_max && (
            <span className="px-3 py-1 bg-gray-50 text-gray-600 rounded-full text-sm font-medium">
              Salário a combinar
            </span>
          )}
          {data?.data?.salary_min && data?.data?.salary_max && (
            <span className="px-3 py-1 bg-blue-50 text-blue-600 rounded-full text-sm font-medium">
              {formatCurrency(data?.data?.salary_min)} -{" "}
              {formatCurrency(data?.data?.salary_max)}
            </span>
          )}
        </div>
      </div>

      <div className="p-8">
        <div className="grid md:grid-cols-3 gap-8">
          <div className="md:col-span-2 space-y-8">
            <section>
              <h3 className="text-lg font-semibold text-gray-900 mb-4">
                Atividades
              </h3>
              <div className="space-y-3">
                <div className="flex flex-wrap gap-2">
                  {data?.data?.activities?.map((activity) => (
                    <span
                      key={activity.position_type?.id}
                      className="px-3 py-1.5 bg-green-50 text-green-600 rounded-lg text-sm"
                    >
                      {activity.position_type?.title || "-"}
                    </span>
                  ))}
                </div>
                <div className="mt-4">
                  <h4 className="font-medium text-gray-700 mb-2 text-sm">
                    Além disso
                  </h4>
                  <p className="text-gray-600">
                    {data?.data?.others_activities || "-"}
                  </p>
                </div>
              </div>
            </section>

            <section>
              <h3 className="text-lg font-semibold text-gray-900 mb-4">
                Requisitos
              </h3>
              <div className="space-y-3">
                {data?.data?.requirements?.map((requirement) => (
                  <div className="flex items-center gap-2 text-gray-600">
                    <CheckCircle2 className="w-5 h-5 text-green-500" />
                    {requirement.position_type?.title || "-"}
                  </div>
                ))}
              </div>
              <div className="mt-4">
                <h4 className="font-medium text-gray-700 mb-2 text-sm">
                  Além disso
                </h4>
                <p className="text-gray-600">
                  {data?.data?.others_requirements || "-"}
                </p>
              </div>
            </section>

            <section>
              <h3 className="text-lg font-semibold text-gray-900 mb-4">
                Sistemas
              </h3>
              <div className="bg-gray-50 rounded-lg p-4">
                <p className="text-gray-500 italic">
                  {data?.data?.systems
                    ?.map((system) => system.system.title)
                    .join(", ") || "Nenhum sistema especificado"}
                </p>
              </div>
            </section>
          </div>

          <div className="space-y-8">
            <section>
              <h3 className="text-lg font-semibold text-gray-900 mb-4">
                Localização
              </h3>
              <div className="bg-gray-50 rounded-lg p-4">
                <p className="text-gray-600 text-sm">
                  {data?.data?.zone?.title
                    ? `${data?.data?.city} - ${data?.data?.zone?.title} - ${data?.data?.state}`
                    : `${data?.data?.city} - ${data?.data?.state}`}
                </p>
              </div>
            </section>

            <section>
              <h3 className="text-lg font-semibold text-gray-900 mb-4">
                Benefícios
              </h3>
              <div className="space-y-3">
                {data?.data?.benefits?.map((benefit) => (
                  <div className="flex items-center gap-2 text-gray-600">
                    <Award className="w-5 h-5 text-purple-500" />
                    {benefit.benefit.title}
                  </div>
                ))}

                <div className="mt-4">
                  <h4 className="font-medium text-gray-700 mb-2 text-sm">
                    Além disso
                  </h4>
                  <p className="text-gray-600">
                    {data?.data?.others_benefits || "-"}
                  </p>
                </div>
              </div>
            </section>

            {/* Contato */}
            {/* <section>
              <h3 className="text-lg font-semibold text-gray-900 mb-4">
                Contato do Recrutador
              </h3>
              <div className="space-y-4">
                <div className="flex items-center gap-2 text-gray-500">
                  <Mail className="w-4 h-4" />
                  <span>Não informado</span>
                </div>
                <div className="flex items-center gap-2 text-gray-500">
                  <Phone className="w-4 h-4" />
                  <span>Não informado</span>
                </div>
              </div>
            </section> */}
          </div>
        </div>
      </div>

      <div className="p-6 bg-gray-50 rounded-b-xl border-t border-gray-100">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            <AlertCircle className="w-5 h-5 text-gray-400" />
            <span className="text-sm text-gray-600">
              Candidate-se apenas se atender aos requisitos da vaga
            </span>
          </div>
          <Link href={`${process.env.REACT_APP_CANDIDATE_URL}/vacancy/${id}`}>
            <button className="px-6 py-2 bg-purple-600 text-white rounded-lg hover:bg-purple-700 transition-colors">
              Candidatar-se
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default JobDetailsModal;

import { useQuery } from "@tanstack/react-query";

interface Zone {
  id: number;
  title: string;
  state: string;
  zip_code_start: number;
  zip_code_end: number;
}

interface ZonesResponse {
  data: Zone[];
}

const API_URL = "https://m9s31qy2f7.execute-api.us-east-1.amazonaws.com"; //"http://localhost:8787";
const API_KEY =
  "d0caa3e319a9c65774572f5446ff5c510918341c7783eb782dd76beb263cae9c";

export function useZones() {
  const { data, isLoading, error } = useQuery<ZonesResponse>({
    queryKey: ["zones"],
    queryFn: async () => {
      const response = await fetch(`${API_URL}/zones`, {
        headers: {
          "X-Api-Key": API_KEY,
        },
      });
      return response.json();
    },
  });

  return {
    zones: data?.data || [],
    isLoading,
    error,
  };
}

import { ThemeProvider } from "@mui/material/styles";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

import Footer from "./components/Footer";
import Navbar from "./components/Navbar";

import ScrollToTop from "./components/ScrollToTop";
import About from "./pages/About";
import Assessoria from "./pages/Assessoria";
import ChooseRole from "./pages/ChooseRole";
import Enterprises from "./pages/Entreprises";
import Home from "./pages/Home";
import Jobs from "./pages/Jobs";
import Talents from "./pages/Talents";
import TalentsBank from "./pages/TalentsBank";
import TalentsManager from "./pages/TalentsManager";

import GlobalModal from "./components/modal-view/container";
import theme from "./ConnectabilTheme";
import { HooksProvider } from "./hooks";
import { Community } from "./pages/Community";

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <HooksProvider>
          <Router>
            <div className="min-h-screen bg-white">
              <ScrollToTop />
              <Navbar />
              <GlobalModal />
              <Routes>
                <Route path="/" element={<Home />}></Route>
                <Route path="/talentos" element={<Talents />}></Route>
                <Route path="/vagas" element={<Jobs />}></Route>
                <Route path="/empresas" element={<Enterprises />}></Route>
                <Route path="/banco_talentos" element={<TalentsBank />}></Route>
                <Route
                  path="/gerenciador_talentos"
                  element={<TalentsManager />}
                ></Route>
                <Route path="/assessoria" element={<Assessoria />}></Route>
                <Route path="/triagem" element={<ChooseRole />}></Route>
                <Route path="/sobre" element={<About />}></Route>
                <Route path="/comunidade" element={<Community />}></Route>
              </Routes>
              <Footer />
            </div>
          </Router>
        </HooksProvider>
      </ThemeProvider>
    </QueryClientProvider>
  );
}

export default App;

import { Button, Container, Fade, Grid, Link } from "@mui/material";
import CarrouselEnterprises from "../components/layout/CarrouselEnterprises";
import styles from "./Home.module.css";

import JobDetailsModal from "../components/JobDetail";
import { Loading } from "../components/Loading";
import { Opportunities } from "../components/Opportunities";
import { useJobs } from "../hooks/useJobs";
import { useModal } from "../hooks/useModal";
import imgBgJobs from "../imgs/Home_BGJobs@2x.png";
import imgHero from "../imgs/Home_Cover@2x.png";
import onMedia1 from "../imgs/onMedia_1@2x.png";
import onMedia2 from "../imgs/onMedia_2@2x.png";
import onMedia3 from "../imgs/onMedia_3@2x.png";

function Home() {
  const { data, isLoading } = useJobs();
  const { openModal, closeModal } = useModal();

  function handleOpenModal(id: number) {
    openModal({
      view: <JobDetailsModal id={id} onClose={closeModal} />,
      size: "lg",
    });
  }

  const listOnMedia = [
    {
      img: onMedia1,
      imgAlt: "Rubia Basile, Vitória e Thaís posando para foto respectivamente",
      title:
        "Connectabil abre rodada de investimentos para impulsionar expansão geográfica",
      exercept:
        "Startup conecta candidatos das áreas contábil e financeira a escritórios e permite agilizar o processo de seleção...",
      link: "https://revistapegn.globo.com/startups/noticia/2023/06/connectabil-abre-rodada-de-investimentos-para-impulsionar-expansao-geografica.ghtml",
    },
    {
      img: onMedia2,
      imgAlt:
        "Foto de Roberto Dias escrito 'Meu sonho é transformar todo contador em investidor de empresas tecnológicas do seu próprio ecossistema'",
      title:
        "Contadores investem na Connectabil, impulsionando a inovação no mercado de contabilidade",
      exercept:
        "A participação da Balance Ventures e um grupo de empresários do setor contábil...",
      link: "https://www.robertodiasduarte.com.br/contadores-investem-na-connectabil-impulsionando-a-inovacao-no-mercado-de-contabilidade/",
    },
    {
      img: onMedia3,
      imgAlt: "Vitória posando de braços cruzados e blazer verde",
      title:
        "Conta Azul Con 2023: Connectabil divulga plataforma que otimiza contratações na área contábil",
      exercept:
        "A Connectabil, plataforma que conecta os melhores profissionais às vagas de emprego em contabilidade e finanças...",
      link: "https://www.contabeis.com.br/noticias/57513/connectabil-garante-contratacao-assertiva-na-contabilidade/",
    },
  ];

  function mountOnMediaPost(
    img: string,
    imgAlt: string,
    title: string,
    exercept: string,
    link: string
  ) {
    return (
      <Grid container item lg className={styles.onMediaContainer}>
        <Grid item>
          <img src={img} alt={imgAlt} />
        </Grid>
        <Grid container item p={3} justifyContent={"flex-end"} rowGap={3}>
          <Grid container item rowGap={1}>
            <Grid item sx={{ display: { xs: "none", sm: "none", md: "flex" } }}>
              <h6 className="disableMarginBlock">{title}</h6>
            </Grid>
            <Grid item sx={{ display: { xs: "flex", sm: "flex", md: "none" } }}>
              <h5 className="disableMarginBlock">{title}</h5>
            </Grid>
            <Grid item>
              <p className="disableMarginBlock">{exercept}</p>
            </Grid>
          </Grid>
          <Grid item>
            <a href={link} target="_blank" rel="noreferrer">
              <Button variant="text" color="primary" size="small">
                Continuar Lendo
              </Button>
            </a>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  return (
    <Container maxWidth={false} disableGutters={true}>
      <Container
        maxWidth={false}
        disableGutters={true}
        sx={{
          backgroundImage: `url(${imgHero})`,
          backgroundSize: "cover",
          backgroundPosition: { xs: "right -40px top 56px", lg: "bottom" },
          backgroundRepeat: "no-repeat",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            sm={12}
            lg={7}
            paddingBottom={10}
            paddingTop={20}
            rowGap={8}
          >
            <Grid item xs sm lg>
              <h1 className={`disableMarginBlock ${styles.h1Cover}`}>
                A maior plataforma de vagas e talentos do{" "}
                <span className={styles.greenText}>
                  mercado contábil e financeiro.
                </span>
              </h1>
            </Grid>
            <Grid container item columnGap={3} rowGap={{ xs: 3, sm: 3 }}>
              <Grid xs={12} sm={12} lg item>
                <Link
                  href={`${process.env.REACT_APP_CUSTOMER_URL}/auth`}
                  underline="none"
                >
                  <Button variant="contained" color="inherit" fullWidth={true}>
                    Empresa publique uma vaga
                  </Button>
                </Link>
              </Grid>
              <Grid xs={12} sm={12} lg item>
                <Link
                  href={`${process.env.REACT_APP_CANDIDATE_URL}/auth2`}
                  underline="none"
                >
                  <Button variant="contained" color="primary" fullWidth={true}>
                    Talento cadastre-se Grátis
                  </Button>
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Container>

      <Container>
        <CarrouselEnterprises />
      </Container>

      <Container
        maxWidth={false}
        disableGutters={true}
        sx={{
          backgroundImage: `url(${imgBgJobs})`,
          backgroundSize: "contain",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          position: "relative",
          zIndex: "1",
          backgroundColor: "#FAFAFA",
        }}
      >
        <Container>
          <Grid container py={10}>
            <Grid container item lg={2}></Grid>
            <Grid
              container
              item
              lg={8}
              rowGap={6}
              zIndex={2}
              justifyContent={"center"}
            >
              {isLoading && <Loading />}
              {!isLoading && (
                <Fade in={isLoading}>
                  <Opportunities
                    handleOpenModal={handleOpenModal}
                    jobs={data?.data || []}
                  />
                </Fade>
              )}
            </Grid>
          </Grid>
        </Container>
      </Container>

      <Container>
        <Grid container rowGap={5} py={10}>
          <Grid container item lg={8} rowGap={2}>
            <Grid item sx={{ display: { xs: "none", sm: "none", md: "flex" } }}>
              <h2 className="disableMarginBlock">Na Mídia</h2>
            </Grid>
            <Grid item sx={{ display: { xs: "flex", sm: "flex", md: "none" } }}>
              <h2 className="disableMarginBlock">Na Mídia</h2>
            </Grid>
            <Grid item>
              <p>
                A Connectabil vem ganhando destaque no mercado contábil e
                financeiro. Conheça um pouco do que estão falando sobre nós.
              </p>
            </Grid>
          </Grid>

          <Grid container item columnGap={3} rowGap={3}>
            {listOnMedia.map((post) => {
              return mountOnMediaPost(
                post.img,
                post.imgAlt,
                post.title,
                post.exercept,
                post.link
              );
            })}
          </Grid>
        </Grid>
      </Container>
    </Container>
  );
}

export default Home;
